import React, { Fragment } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paginations from '@material-ui/lab/Pagination';
import { AiOutlineFileText } from 'react-icons/ai';
import { BiSearch } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import homeLoad from '../../../services/home';
import url from '../../../@jumbo/constants/UrlConstant';
import { setActiveHomePagination, setHomeDefault, setKeywordHome } from '../../../redux/actions';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#F6F8FA',
    color: '#7B7B7B',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {},
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('09122', 'Lab Biogen Malang lorem', 'Skylar Lipshutz', '12 Oct 2019', 'jalan Ir hj juanda'),
  createData('09122', 'Lab Biogen Malang lorem', 'Skylar Lipshutz', '12 Oct 2019', 'jalan Ir hj juanda'),
  createData('09122', 'Lab Biogen Malang lorem', 'Skylar Lipshutz', '12 Oct 2019', 'jalan Ir hj juanda'),
  createData('09122', 'Lab Biogen Malang lorem', 'Skylar Lipshutz', '12 Oct 2019', 'jalan Ir hj juanda'),
  createData('09122', 'Lab Biogen Malang lorem', 'Skylar Lipshutz', '12 Oct 2019', 'jalan Ir hj juanda'),
  createData('09122', 'Lab Biogen Malang lorem', 'Skylar Lipshutz', '12 Oct 2019', 'jalan Ir hj juanda'),
  createData('09122', 'Lab Biogen Malang lorem', 'Skylar Lipshutz', '12 Oct 2019', 'jalan Ir hj juanda'),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  ul: {
    '& .MuiPaginationItem-page': {
      color: '#131313',
      background: '#F7F7FA',
      border: 'none',
    },
    '& .Mui-selected': {
      color: '#fff',
      background: '#043ED0',
    },
  },
});

const TabHasilTerbaru = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [keywordLocal, setKeyword] = React.useState('');

  const { HomeData, pagination, activePaginate, keyword } = useSelector(({ home }) => home);

  React.useEffect(() => {
    dispatch(homeLoad.getHasilBaru());
  }, [dispatch, activePaginate, keyword]);

  React.useEffect(() => {
    return () => {
      dispatch(setHomeDefault());
    };
  }, [dispatch]);

  const HandlePagination = (event, current_page) => {
    dispatch(setActiveHomePagination(current_page));
  };

  const handleKeyword = () => {
    dispatch(setKeywordHome(keywordLocal));
  };
  return (
    <Fragment>
      <div className="table-wrap-hasil-terbaru">
        <div className="head-search">
          <h3>Data Hasil Terbaru</h3>
          <div className="search-style">
            <input type="text" placeholder="Search By" onChange={e => setKeyword(e.target.value)} />
            <button onClick={handleKeyword}>
              <BiSearch />
            </button>
          </div>
        </div>
        <TableContainer>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>No Reg</StyledTableCell>
                <StyledTableCell align="left">No Lab</StyledTableCell>
                <StyledTableCell align="left">Nama</StyledTableCell>
                <StyledTableCell align="left">Tanggal Periksa</StyledTableCell>
                <StyledTableCell align="left">Alamat</StyledTableCell>
                <StyledTableCell align="left">Hasil</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {HomeData.map(row => (
                <StyledTableRow key={row.noreg}>
                  <StyledTableCell component="th" scope="row">
                    {row.noreg}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.nolab}</StyledTableCell>
                  <StyledTableCell align="left">{row.nama}</StyledTableCell>
                  <StyledTableCell align="left">{row.tanggal_periksa}</StyledTableCell>
                  <StyledTableCell align="left">{row.alamat}</StyledTableCell>
                  <StyledTableCell align="left">
                    <a target="_blank" href={`${url.baseContent}${row.file}`} className="hasil-table">
                      <AiOutlineFileText />
                      <p>{row.keterangan}</p>
                    </a>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="style-pagination">
        <Paginations
          className={classes.ul}
          count={pagination.last_page}
          variant="outlined"
          shape="rounded"
          page={activePaginate}
          onChange={HandlePagination}
        />
      </div>
    </Fragment>
  );
};

export default TabHasilTerbaru;
