import React, { Fragment, useEffect } from 'react';
import './Home.css';
import Atta from '@material-ui/icons/AttachFile';
import Chec from '@material-ui/icons/CheckCircle';
import Paginations from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import homeLoad from '../../../services/home';
import { setActiveHomePagination, setSelectedData, setHomeDefault } from '../../../redux/actions';

export const useStyles = makeStyles(theme => ({
  ul: {
    '& .MuiPaginationItem-page': {
      color: '#131313',
      background: '#F7F7FA',
      border: 'none',
    },
    '& .Mui-selected': {
      color: '#fff',
      background: '#043ED0',
    },
  },
}));

const TabInformasi = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { HomeData, pagination, activePaginate } = useSelector(({ home }) => home);
  useEffect(() => {
    dispatch(homeLoad.getList());
  }, [dispatch, activePaginate]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setHomeDefault());
  //   };
  // }, [dispatch]);

  const HandlePagination = (event, current_page) => {
    dispatch(setActiveHomePagination(current_page));
  };

  const HandleDetail = data => {
    dispatch(setSelectedData(data));
    history.push('/detail-informasi');
  };

  return (
    <Fragment>
      <div className="wrap-tab-informasi">
        {HomeData.map((d, i) => {
          return (
            <div onClick={() => HandleDetail(d)} className="wrap-card-informasi">
              <div key={i}>
                <div className="card-content">
                  <h2>{d.title}</h2>
                  <div className="date-attachment">
                    {/* <p>30 mei 2022 02:19:54</p> */}
                    <div className="attachment">
                      <Atta fontSize="small" />
                      <p>{d.count_file} file attachment</p>
                    </div>
                  </div>

                  <div className="description-card-informasi">
                    <p>Deskripsi</p>
                    <div className="cut-text" dangerouslySetInnerHTML={{ __html: d.deskripsi }}></div>
                  </div>

                  <div className="audience-card-informasi">
                    <p>Audience</p>
                    <div className="box-audience">
                      <div className={`item-audience ${d.type_audience === 'Personal' ? 'active' : ''}`}>
                        <p>Personal</p>
                        <Chec fontSize="small" />
                      </div>
                      <div className={`item-audience ${d.type_audience === 'Dokter' ? 'active' : ''}`}>
                        <p>Dokter</p>
                        <Chec fontSize="small" />
                      </div>
                      <div className={`item-audience ${d.type_audience === 'Instansi' ? 'active' : ''}`}>
                        <p>instansi</p>
                        <Chec fontSize="small" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="style-pagination">
        <Paginations
          className={classes.ul}
          count={pagination.last_page}
          variant="outlined"
          shape="rounded"
          page={pagination.current_page}
          onChange={HandlePagination}
        />
      </div>
    </Fragment>
  );
};

export default TabInformasi;
