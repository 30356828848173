import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#000',
    },
  },
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonSignIn: {
    height: '53px',
    backgroundColor: '#0139CE',
    borderRadius: '4px',
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 24,
    color: theme.palette.text.primary,
    fontWeight: '500',
    fontSize: '36px',
  },
  textFieldRoot: {
    backgroundColor: '#eaf0f7',
    borderRadius: '4px',
    height: '53px',
    border: 'none',
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    '& label.Mui-focused': {
      display: 'none',
    },
    '& legend': {
      display: 'none',
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
  SelectRoot: {
    width: '100%',
    height: '46px',
    marginBottom: '32px',
    borderRadius: '4px',

    '& .MuiFilledInput-root': {
      backgroundColor: '#eaf0f7',
      border: 'none',
    },
  },
}));
//variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles({ variant });

  const onSubmit = () => {
    dispatch(AuhMethods[method].onLogin({ email, password, role }));
  };

  return (
    <div className="login-wrap">
      <div className="img-log">
        <img src="/images/bg-log.png" alt="" srcset="" />
      </div>
      <div className="wrap-auth">
        <div className="content-auth">
          <div className="logo-login-auth">
            <img src="/images/labiogen.png" alt="" srcset="" />
          </div>
          <Typography component="div" variant="h1" className={classes.titleRoot}>
            Sign in
          </Typography>
          <form>
            <p className="title-inp">Role</p>
            <FormControl variant="filled" className={classes.SelectRoot}>
              <InputLabel id="demo-simple-select-filled-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={role}
                disableUnderline
                onChange={e => setRole(e.target.value)}>
                className ={classes.textFieldRoot}
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>Personal</MenuItem>
                <MenuItem value={2}>Dokter</MenuItem>
                <MenuItem value={3}>Instansi</MenuItem>
              </Select>
            </FormControl>
            <p className="title-inp">User ID</p>
            <input
              className="input-login"
              type="text"
              placeholder="Enter User ID"
              defaultValue={email}
              onChange={event => setEmail(event.target.value)}
            />
            {/* <TextField
              fullWidth
              hiddenLabel
              onChange={event => setEmail(event.target.value)}
              defaultValue={email}
              margin="normal"
              variant="filled"
              className={classes.textFieldRoot}
              placeholder="Enter The ID"
            /> */}

            <p className="title-inp">Password</p>
            <input
              className="input-login"
              type="password"
              placeholder="Enter Password"
              defaultValue={password}
              onChange={event => setPassword(event.target.value)}
            />
            {/* <TextField
              type="password"
              fullWidth
              hiddenLabel
              InputLabelProps={{ shrink: false }}
              onChange={event => setPassword(event.target.value)}
              defaultValue={password}
              margin="normal"
              variant="filled"
              className="input-login"
              placeholder="Enter Password"
            /> */}

            <Button onClick={onSubmit} variant="contained" color="primary" fullWidth className={classes.buttonSignIn}>
              <IntlMessages id="appModule.signIn" />
            </Button>
          </form>
        </div>
      </div>
      <ContentLoader />
    </div>
  );
};

export default SignIn;
